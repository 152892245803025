<template>
  <el-dialog
    v-if="openlookDialog"
    :visible.sync="openlookDialog"
    title="预览视频"
    :append-to-body="true"
    :before-close="handleClose"
    width="60%"
  >
    <div class="videoPlayer">
      <xg-player ref="player" :url="videoUrl" @triggerEvent="triggerEvent" ></xg-player>
    </div>
  </el-dialog>
</template>

<script>
import config from "../../../utils/serverUrlConfig";
import local from "@/utils/localStroage.js";
import XgPlayer from './XgPlayer.vue';

export default {
    data(){
        return{
            openlookDialog:false,
            videoUrl:"",
        }
    },
    components: {
      XgPlayer,
    },
    methods:{
      loadVideo(data) {
        this.videoUrl  = `${config.baseServerUrl}/api/m3u8/${data}?token=${local.get('userInfo').token}`
      },
      triggerEvent(value){
        console.log("是否播放：",value);
      },
      handleClose() {
        this.$refs.player.dPlayer.destroy()
        this.openlookDialog = false;
      },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .video-js .vjs-time-control{
  display: inline-block !important;
  padding: 0 2px !important;
  text-align: center;
}
.videoPlayer {
  height: 600px;
  width: auto;
  margin: 0 auto;
}
</style>
