<template>
  <div class="tags-box">
    <el-dialog
      :title="'标签编辑'"
      :visible.sync="dialogVisible"
      width="850px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" label-width="100px">
        <div class="tagTitle">标签:&nbsp;&nbsp;{{ getTagStr() }}</div>
        <el-collapse v-model="activeNames">
          <el-collapse-item
            :title="item.cat_name"
            :name="item.id"
            v-for="item in tags"
            :key="item.id"
            :disabled="!item.isFold"
          >
            <template slot="title">
              <span style="color: black"
                >{{ item.cat_name
                }}<span v-if="item.required">(必选)</span></span
              >
            </template>
            <div class="el-form-item_a" v-if="item.tags.length > 0">
              <el-checkbox-group v-model="form.tags">
                <el-checkbox
                  v-for="(item1, index) in item.tags"
                  :key="index"
                  :label="item1.id"
                  @change="handleTags(item1.tag_name)"
                >
                  {{ item1.tag_name }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="small">取 消</el-button>
        <el-button type="primary" @click="editTags" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api";

export default {
  props: ["type"],
  data() {
    return {
      dialogVisible: false,
      tags: [],
      tagStr: [],
      form: {
        ids: [],
        tags: [],
      },
      activeNames: undefined,
    };
  },
  methods: {
    // 打开弹窗并赋值需要修改
    open(videoIds) {
      this.getTagList();
      this.dialogVisible = true;
      this.form.ids = videoIds || [];
    },
    getTagList() {
      API.getTagTree().then((res) => {
        if (res.code === 0) {
          this.tags = res.data;
        }
      });
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
      this.tagStr = [];
      this.tags = [];
      this.form.ids = [];
      this.form.tags = [];
      this.$refs.form.resetFields();
    },
    getTagStr() {
      var str = "";
      if (this.tagStr.length > 0) {
        for (let index = 0; index < this.tagStr.length; index++) {
          if (index === 0) {
            str += this.tagStr[index];
          } else {
            str += "," + this.tagStr[index];
          }
        }
      }
      return str;
    },
    editTags() {
      if (!this.form.tags.length)
        return this.$message.error("请至少选择一个标签");
      let req = {
        ids: this.form.ids,
        tags: this.form.tags,
      };
      if (this.type == "movie") {
        API.batchUpdateTag(req)
          .then((res) => {
            if (res.code === 0) {
              this.$message({
                message: "编辑成功！",
                type: "success",
              });
              this.$emit("handlerClose");
            } else {
              this.$message.error("编辑失败！");
            }
            this.handleClose();
          })
          .catch(() => {
            this.handleClose();
          });
      } else {
        API.batchUpdateShortTag(req)
          .then((res) => {
            if (res.code === 0) {
              this.$message({
                message: "编辑成功！",
                type: "success",
              });
              this.$emit("handlerClose");
            } else {
              this.$message.error("编辑失败！");
            }
            this.handleClose();
          })
          .catch(() => {
            this.handleClose();
          });
      }
    },
    handleTags(valus) {
      if (this.tagStr.length === 0 || this.tagStr.indexOf(valus) == -1) {
        this.tagStr.push(valus);
      } else {
        this.tagStr.map((val, i) => {
          if (val === valus) {
            this.tagStr.splice(i, 1);
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.tags-box {
  .input-box {
    margin-top: 15px;
    text-align: left;
  }
  .el-input {
    width: 200px;
    margin-right: 10px;
  }
}
</style>
