<template>
  <div class="warp">
    <div class="title">普通搜索</div>
    <!--    <el-tabs v-model="activeName" @tab-click="handleClick">-->
    <!--      <el-tab-pane :label="item.label" :name="item.value" :key="item.value" v-for="item in list">-->
    <!--      </el-tab-pane>-->
    <!--    </el-tabs>-->
    <el-form ref="form" :model="form" label-width="120px">
      <el-row :gutter="20">
        <!-- 短视频 -->
        <el-col :span="6" v-if="delUrl === '/api/video/short/destroy'">
          <el-form-item label="短视频ID">
            <el-input v-model="form.id"></el-input>
          </el-form-item>
        </el-col>

        <!-- 视频id -->
        <el-col :span="6" v-else>
          <el-form-item label="ID">
            <el-input v-model="form.id"></el-input>
          </el-form-item>
        </el-col>

        <!-- 视频 + 短视频公共 -->
        <el-col :span="6">
          <el-form-item label="UP主ID">
            <el-input v-model="form.vlogger_id"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="唯一标识">
            <el-input v-model="form.hash_id"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="文件状态">
            <el-select v-model="form.fs_resource_status" placeholder="请选择">
              <el-option
                :label="item.value"
                :value="item.key"
                v-for="item in resourceList"
                :key="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <!-- 短视频查询条件 -->
        <template v-if="delUrl === '/api/video/short/destroy'">
          <el-col :span="6">
            <el-form-item label="主视频ID">
              <el-input v-model="form.video_id"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="短视频标题">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否竖版视频">
              <el-select v-model="form.is_vertical" placeholder="请选择">
                <el-option
                  :label="item.value"
                  :value="item.key"
                  v-for="item in verticalList"
                  :key="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </template>

        <!-- 视频查询条件 -->
        <template v-else>
          <el-col :span="6">
            <el-form-item label="连载名称">
              <el-select v-model="form.sets_id" placeholder="请选择">
                <el-option
                  :label="item.title"
                  :value="item.id"
                  v-for="item in parentsList"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="视频标题">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="媒体类型">
              <el-select v-model="form.media_type" placeholder="请选择">
                <el-option
                  :label="item.value"
                  :value="item.key"
                  v-for="item in mediaList"
                  :key="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否竖版">
              <el-select v-model="form.is_vertical" placeholder="请选择">
                <el-option
                  :label="item.value"
                  :value="item.key"
                  v-for="item in verticalList"
                  :key="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </template>

        <!-- 视频+短视频公共 -->
        <el-col :span="6">
          <el-form-item label="标签">
            <el-input v-model="form.tags_text"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="来源产品">
            <el-input v-model="form.from_pro_id"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="编辑者">
            <el-input v-model="form.edit_by"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="上传者">
            <el-input v-model="form.terminal_cdo"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="状态">
            <el-select v-model="form.status" placeholder="请选择">
              <el-option
                :label="item.value"
                :value="item.key"
                v-for="item in statuslist"
                :key="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <!-- 视频管理 -->
        <template v-if="delUrl !== '/api/video/short/destroy'">
          <el-col :span="6">
            <el-form-item label="时长">
              <el-select v-model="form.length" placeholder="请选择">
                <el-option
                  :label="item.value"
                  :value="item.key"
                  v-for="item in lengthlist"
                  :key="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="入库时间">
              <el-date-picker
                v-model="create_time_form"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="更新时间">
              <el-date-picker
                v-model="update_time_form"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </template>

        <el-col class="cols">
          <el-button type="primary" @click="submit(form)">提交</el-button>
          <el-button @click="resetData">重置</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "tabs.vue",
  data() {
    return {
      activeName: "0",
      form: {},
      list: [
        {
          label: "全部",
          value: "0",
        },
        {
          label: "待编辑",
          value: "1",
        },
        {
          label: "全部",
          value: "2",
        },
        {
          label: "准备预热",
          value: "3",
        },
        {
          label: "预热中",
          value: "4",
        },
        {
          label: "预热异常",
          value: "5",
        },
        {
          label: "已就绪",
          value: "6",
        },
        {
          label: "已弃用",
          value: "7",
        },
      ],
      // 文件状态
      resourceList: [
        { key: "waiting", value: "待检查" },
        { key: "Uploading", value: "文件上传中" },
        { key: "UploadError", value: "文件上传失败" },
        { key: "UploadCompleted", value: "文件上传完成" },
        { key: "FileMerging", value: "文件合并中" },
        { key: "FileConverting", value: "文件转换中" },
        { key: "FileMergeError", value: "文件合并失败" },
        { key: "FileConvertError", value: "文件转换失败" },
        { key: "CompletedFail", value: "处理失败" },
        { key: "CompressCompleted", value: "压缩完成" },
        { key: "Completed", value: "处理完成" },
      ],
      // 是否竖版
      verticalList: [
        { key: true, value: "是" },
        { key: false, value: "否" },
      ],
      // 媒体类型
      mediaList: [
        { key: 0, value: "视频" },
        { key: 1, value: "图文" },
      ],
      // 状态
      statuslist: [
        {
          key: 0,
          value: "准备中",
        },
        {
          key: 7,
          value: "弃用",
        },
        {
          key: 10,
          value: "完成&可使用",
        },
      ],
      // 连载名称
      setsIdlist: [{ key: 0, value: "" }],
      // 时长
      lengthlist: [
        { key: null, value: "全部" },
        { key: "max", value: "小于10分钟" },
        { key: "min", value: "10分钟以上" },
      ],
      create_time_form: "",
      update_time_form: "",
    };
  },
  props: ["delUrl", "parentsList"],
  methods: {
    handleClick() {},
    resetData() {
      this.form = {};
      this.create_time_form = "";
      this.update_time_form = "";
      this.$emit("resetData", "");
    },
    submit(data) {
      // 入库时间
      if (this.create_time_form) {
        let creat = new Date(this.create_time_form[0]).getTime() / 1000;
        let end = new Date(this.create_time_form[1]).getTime() / 1000;
        data.create_time = {
          max: end,
          min: creat,
        };
      } else {
        data.create_time = null;
      }
      // 更新时间
      if (this.update_time_form) {
        let creat = new Date(this.update_time_form[0]).getTime() / 1000;
        let end = new Date(this.update_time_form[1]).getTime() / 1000;
        data.update_time = {
          max: end,
          min: creat,
        };
      } else {
        data.update_time = null;
      }
      // 时长
      if (data.length) {
        data.length == "max"
          ? (data.length = { max: 599 })
          : (data.length = { min: 600 });
      }
      this.$emit("searchData", data);
    },
  },
};
</script>

<style scoped lang="scss">
.warp ::v-deep .el-date-editor,
.warp ::v-deep .el-select {
  width: 100% !important;
}
.el-form-item {
  margin-bottom: 10px;
}
.title {
  width: 100%;
  border-bottom: 1px solid #ebeef5;
  padding: 0 0 10px 0;
  box-sizing: border-box;
  margin: 0 0 20px 0;
}
.cols {
  display: flex;
  flex-direction: row-reverse;
  margin: 0 20px 10px 0;
  .el-button {
    margin: 0 0 0 10px;
  }
}
</style>
